<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet
        :canBack="true"
        colorIcon="#052633"
        title="Detail Manajemen Tugas"
      />
      <v-card flat color="#052633" rounded="lg" class="mt-3">
        <v-row no-gutters>
          <v-col cols="12" md="8" style="display: grid">
            <div
              class="full_w pa-5 d-flex align-start justify-center flex-column"
            >
              <h2 v-if="!loading && dataSet" class="txt_white">
                {{ `${dataSet.event.tipe} - ${dataSet.event.klien.nama}` }}
              </h2>
              <v-skeleton-loader
                v-if="loading"
                class="rounded-lg"
                width="100px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <div v-if="!loading && dataSet" class="mt-1 d-flex align-center">
                <v-icon color="white" class="mr-2"
                  >mdi-calendar-check-outline</v-icon
                >
                <h4 class="not_bold txt_white text-capitalize">
                  {{ dataSet.step ? dataSet.step.step : "-" }}
                </h4>
              </div>
              <v-skeleton-loader
                v-if="loading"
                class="rounded-lg mt-1"
                width="400px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <div class="mt-2 d-flex align-center">
                <v-card
                  v-if="!loading && dataSet"
                  class="pa-2 mr-2 text-capitalize"
                  flat
                  dark
                  :color="
                    dataSet.status == 'done'
                      ? '#26AF65'
                      : dataSet.status == 'ongoing'
                      ? '#EDD57B'
                      : '#DC3545'
                  "
                >
                  {{ dataSet.status }}
                </v-card>
                <v-skeleton-loader
                  v-if="loading"
                  class="rounded-lg mr-2"
                  width="100px"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
                <v-card
                  v-if="!loading && dataSet"
                  class="pa-2 text-capitalize"
                  flat
                  dark
                  :color="
                    dataSet.prioritas == 'low'
                      ? '#408785'
                      : dataSet.prioritas == 'high'
                      ? '#633B18'
                      : '#BD9028'
                  "
                >
                  {{ dataSet.prioritas }}
                </v-card>
                <v-skeleton-loader
                  v-if="loading"
                  class="rounded-lg"
                  width="100px"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="px-4 py-2 d-flex align-center justify-end"
          >
            <img
              src="../../assets/img/clock.png"
              height="100px"
              style="object-fit: contain"
            />
          </v-col>
        </v-row>
      </v-card>
      <div class="mt-5 mb-2 px-2">
        <h3>Yang Bertugas</h3>
        <v-row no-gutters class="mt-2">
          <v-col cols="12" md="6" class="pa-2">
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <v-icon color="#052633" class="mr-2"
                >mdi-account-box-outline</v-icon
              >
              <h4 class="text-capitalize txt_color_default">
                {{ dataSet.karyawan.nama ? dataSet.karyawan.nama : "-" }}
              </h4>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <v-icon color="#052633" class="mr-2"
                >mdi-account-settings-outline
              </v-icon>
              <h4 class="text-capitalize txt_color_default">
                {{
                  dataSet.karyawan.status_karyawan
                    ? dataSet.karyawan.status_karyawan
                    : "-"
                }}
              </h4>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" class="pa-2">
            <div v-if="dataSet && !loading" class="mt-2 d-flex align-center">
              <v-icon color="#052633" class="mr-2"
                >mdi-briefcase-outline</v-icon
              >
              <h4 class="text-capitalize txt_color_default">
                {{ dataSet.karyawan.jabatan ? dataSet.karyawan.jabatan : "-" }}
              </h4>
            </div>
            <v-skeleton-loader
              v-if="loading"
              class="rounded-lg mt-2"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div class="mt-5 mb-2 px-2 d-flex align-center">
        <h3>Tugas</h3>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!loading && dataSet"
          text
          depressed
          class="text-capitalize"
          :to="`/admin/mk/event/detail/${dataSet.event.id}`"
        >
          Lihat Selengkapnya
        </v-btn>
      </div>
      <v-row v-if="!loading && dataSet.detailTask" no-gutters>
        <v-col cols="12" md="6" class="pa-2">
          <v-card flat outlined min-height="90px">
            <v-card flat class="pa-2" color="#CDD0D1" rounded="0">
              <h4 class="not_bold text-center">Rincian Tugas</h4>
            </v-card>
            <div class="pa-2">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-flag-checkered</v-icon>
                <h4>
                  {{ dataSet.detailTask.goal }}
                </h4>
              </div>
              <div class="mt-2 d-flex align-center">
                <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                <h4>
                  {{ dataSet.detailTask.tenggat }}
                </h4>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
          <v-card flat outlined min-height="90px">
            <v-card flat class="pa-2" color="#CDD0D1" rounded="0">
              <h4 class="not_bold text-center">Progres</h4>
            </v-card>
            <div class="pa-2 d-flex align-center">
              <div
                style="flex-grow: 1"
                class="d-flex align-center justify-center"
              >
                <h4>
                  {{
                    `${dataSet.detailTask.progres} / ${
                      dataSet.detailTask.target
                    } ${
                      dataSet.detailTask.metric == "angka"
                        ? ""
                        : dataSet.detailTask.metric
                    }`
                  }}
                </h4>
              </div>
              <v-divider vertical></v-divider>
              <div
                style="flex-grow: 1"
                class="d-flex align-center justify-center"
              >
                <v-progress-circular
                  :value="
                    (dataSet.detailTask.progres / dataSet.detailTask.target) *
                    100
                  "
                  color="#106967"
                  :size="60"
                  :width="7"
                  :rotate="270"
                >
                  <h5>{{ dataSet.detailTask.progres }}</h5>
                </v-progress-circular>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="pa-2" flat outlined v-if="!loading && !dataSet.detailTask">
        <h4 class="text-center not_bold" style="color: #697174">
          Rincian Tugas dan Progres tidak ada
        </h4>
      </v-card>
      <div>
        <v-skeleton-loader
          v-if="loading"
          class="rounded-lg"
          height="100px"
          type="image"
        ></v-skeleton-loader>
      </div>
      <h3 class="mt-5 mb-2 px-2">Info Layanan</h3>
      <v-row v-if="!loading && dataSet" no-gutters class="pb-4">
        <v-col cols="12" md="8" class="px-2 mt-2">
          <div
            class="rounded"
            style="overflow: hidden; border: 0.05em solid #cdd0d1"
          >
            <v-row no-gutters>
              <v-col cols="4">
                <v-card
                  height="100%"
                  flat
                  rounded="0"
                  style=""
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Nama Klien
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  height="100%"
                  flat
                  rounded="0"
                  style="height: 100%"
                  class="pa-3"
                >
                  {{ dataSet.event.customer ? dataSet.event.customer : "-" }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Tujuan Event
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card flat height="100%" rounded="0" class="pa-3">
                  {{
                    dataSet.event.tujuan_event
                      ? dataSet.event.tujuan_event
                      : "-"
                  }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Tipe Klien
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3 text-capitalize"
                >
                  {{ dataSet.event.tipe ? dataSet.event.tipe : "-" }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  height="100%"
                  flat
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Layanan
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="d-flex align-center">
                  <v-card height="100%" flat rounded="0" class="pa-3">
                    {{ dataSet.event.layanan ? dataSet.event.layanan : "-" }}
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="dataSet.event.layanan == 'Tes Psikologi'"
                    text
                    depressed
                    class="text-capitalize mr-3"
                    @click="toFile('https://dev-dashboard.anargya.id/')"
                    style="text-decoration-line: underline"
                  >
                    Link Test</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Detail Layanan
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card height="100%" flat rounded="0" class="pa-3">
                  Tanggal {{ dataSet.event.timeline[0].start_date }} to
                  {{
                    dataSet.event.timeline[dataSet.event.timeline.length - 1]
                      .start_date
                  }}, pukul {{ dataSet.event.timeline[0].start_time }} s/d
                  {{
                    dataSet.event.timeline[dataSet.event.timeline.length - 1]
                      .end_time
                  }}
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="px-2 mt-2">
          <div
            class="rounded"
            style="overflow: hidden; border: 0.05em solid #cdd0d1"
          >
            <v-card flat rounded="0" class="pa-3" color="#CDD0D1">
              File Attachment
            </v-card>
            <div class="px-3 my-2">
              <div v-for="(item, n) in dataSet.event.timeline" :key="n">
                <div
                  class="mt-2 rounded d-flex align-center"
                  style="border: 0.05em solid #cdd0d1"
                  v-if="item.file"
                >
                  <div
                    class="rounded pa-2 d-flex align-center justify-center"
                    style="border-right: 0.05em solid #cdd0d1"
                  >
                    <v-icon>mdi-file</v-icon>
                  </div>
                  <div
                    class="pa-2"
                    @click="toFile(item.file)"
                    style="text-decoration-line: underline; cursor: pointer"
                  >
                    File {{ item.step }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader
        v-if="loading"
        type="list-item-three-line, article"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({}),
  },
  nama: "detailEvent",
  data() {
    return {
      menuLimit: false,
      offsetTop: 0,
      loading: true,
      dataSet: null,
      find: "",
      d_create: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Manajemen Tugas",
          disabled: false,
          href: "/admin/mk/task-management",
        },
        {
          text: "Detail Manajemen Tugas",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Nama", value: "nama" },
        { text: "No. Telepon", value: "telepon" },
        { text: "Email", value: "email" },
        { text: "Alamat", value: "alamat" },
      ],
      drag: false,
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toFile(n) {
      window.open(n, "blank");
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/task-management?filter[id]=${this.$route.params.idTask}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data[0];
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    updateData(item) {
      let data = {
        timelines: item,
        data: {
          nama: "Bina Karir",
          tipe_klien: "Project",
          tgl_end: "2022-03-03",
          timeline: 2,
        },
      };
      this.inventory = data;
      this.d_type = "u_status_event";
      this.d_title = "Edit Status Timeline";
      this.d_create = true;
      this.line = true;
    },
  },
};
</script>

<style></style>
